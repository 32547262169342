import React from 'react'
import styled, { css } from 'styled-components'
import { Wrapper, Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

const CARD_MIN_WIDTH = '270px'

const StyledGridScroll = styled.div`
  position: relative;

  ${screenMax.m`
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  `}
`

const StyledGridScrollInner = styled(Wrapper)<{
  $items: number
  $indent?: number
}>`
  ${({ $items }) =>
    $items > 1 &&
    css`
      ${screenMax.m`
        max-width: none;
        width: calc((${$items} * (${CARD_MIN_WIDTH} + ${GTR.M})) + ${GTR.M});
        width: clamp(
          calc((${$items} * (${CARD_MIN_WIDTH} + ${GTR.M})) + ${GTR.M}),
          ${`${76 * $items}vw`},
          ${`${468 * $items}px`}
        );
        padding-right: ${GTR.M};
      `}
    `}

  ${({ $indent }) =>
    $indent &&
    screenMax.m`
    padding: 0 ${$indent}px ${GTR.L};
  `}
`

const StyledCard = styled(Grid.Item)<{ $items: number }>`
  ${({ $items }) =>
    $items > 1 &&
    screenMax.m`
        scroll-snap-align: center;
        width: 70vw;
        width: clamp(${CARD_MIN_WIDTH}, 70vw, 400px);
    `}
`

const Scroll = ({
  children,
  items,
  indent,
}: {
  children: React.ReactElement[]
  items: number
  indent?: number
}): React.ReactElement => {
  const getSpanForMedia = () => {
    if (items === 3) return 4
    if (items === 2) return 6
    return 12
  }

  return (
    <StyledGridScroll>
      <StyledGridScrollInner
        $items={items}
        $indent={indent}
        container
        containerPaddingTop={0}
      >
        <Grid gapFromM="XL">
          {children.map((child) => (
            <StyledCard
              $items={items}
              key={child.props?.data?.id}
              span={getSpanForMedia()}
            >
              {child}
            </StyledCard>
          ))}
        </Grid>
      </StyledGridScrollInner>
    </StyledGridScroll>
  )
}

export default Scroll
