import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Button, ChevronDownIcon, Grid, H, Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import scrollToElement from 'lib/navigation/scrollToElement'
import BreakpointSwitch from 'components/BreakpointSwitch'
import SearchDropdown from './SearchWithDropdown'

const StyledGridItem = styled(Grid.Item)`
  display: flex;
`

const StyledButton = styled(Button.Plain)`
  justify-content: space-between;
  padding: 0;
  width: 100%;
  gap: ${GTR.XS};

  ${screenMin.m`
    justify-content: left;
    width: fit-content;
  `}
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${GTR.S};
  padding: ${GTR.M};
`

const StyledWrapper = styled(Wrapper)<{
  $open: boolean
  $contentHeight: number
}>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  ${({ $open, $contentHeight }) =>
    css`
      ${$open &&
      `
        border-bottom: 1px solid ${COLOR.GREY.LIGHT};
        max-height: ${$contentHeight}px;
      `}
    `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledChevronIcon = styled(ChevronDownIcon)<{ $open: boolean }>`
  transition: transform 0.3s ease-in-out;

  ${({ $open }) => $open && `transform: rotate(-180deg);`}
`

const handleLinkClick = (
  event: React.MouseEvent<Element, MouseEvent>,
  elementId?: string
) => {
  if (!elementId) return

  const element = document.getElementById(elementId)
  if (!element) return

  scrollToElement({
    event: event as React.MouseEvent<HTMLButtonElement, MouseEvent>,
    elementId,
  })
  element.setAttribute('tabindex', '-1')
  element.focus({ preventScroll: true })
}

interface ContentMenuProps {
  sections: GatsbyTypes.Maybe<GatsbyTypes.SectionFragment>[]
  data: {
    generalArticles: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.LocalSearchGeneralArticles, 'index' | 'store'>
    >
    companyArticles?: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.LocalSearchCompanyArticles, 'index' | 'store'>
    >
  }
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>
}

const ContentMenu = ({
  sections,
  data,
  setShowOverlay,
}: ContentMenuProps): React.ReactElement => {
  const navContentRef = useRef<HTMLElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <Wrapper>
        <Grid container containerPaddingTop="M" containerPaddingBottom="M">
          <StyledGridItem spanFromM={6}>
            <StyledButton
              icon={
                <StyledChevronIcon $open={isMenuOpen} color={COLOR.BLACK} />
              }
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-controls="content-menu"
              aria-expanded={isMenuOpen}
            >
              <H
                context
                tag="h2"
                color={COLOR.BLACK}
                margin={0}
                id="content-navigation"
              >
                Contents
              </H>
            </StyledButton>
          </StyledGridItem>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            aboveComponent={
              <Grid.Item startColumn={9} span={5} spanFromL={4}>
                <SearchDropdown data={data} setShowOverlay={setShowOverlay} />
              </Grid.Item>
            }
          />
        </Grid>
      </Wrapper>
      <StyledWrapper
        $open={isMenuOpen}
        $contentHeight={navContentRef.current?.clientHeight}
        id="content-menu"
      >
        <Wrapper
          container
          containerPaddingTop={0}
          containerPaddingBottom={0}
          padding={0}
          paddingFromS={[0, 'XS']}
          paddingFromM={[0, 'S']}
          paddingFromL={[0, 'M']}
          paddingFromXL={[0, 'L']}
        >
          <nav ref={navContentRef} aria-labelledby="content-navigation">
            <StyledList>
              {sections.map(
                (section) =>
                  section && (
                    <li key={section.slug}>
                      <StyledLink
                        to={`#${section.slug}`}
                        onClick={(event) =>
                          handleLinkClick(event, section.slug)
                        }
                        tabIndex={isMenuOpen ? 0 : -1}
                        aria-hidden={!isMenuOpen}
                      >
                        {section.title}
                      </StyledLink>
                    </li>
                  )
              )}
            </StyledList>
          </nav>
        </Wrapper>
      </StyledWrapper>
    </>
  )
}

export default ContentMenu
