import React, { useState, useEffect, useRef } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import qs from 'qs'
import { ArrowRightIcon, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import PATHS from 'lib/navigation/paths'
import { ARTICLE_TYPES } from 'lib/contentful/constants'
import SearchBox from './SearchBox'

const MAX_SEARCH_RESULTS = 3

const StyledWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
`

const StyledDiv = styled.div`
  width: 100%;
`

const StyledDropdown = styled(Wrapper)`
  position: absolute;
  top: ${GTR.XL};
  width: 100%;
  background-color: ${COLOR.WHITE};
  box-shadow: ${BORDER.SHADOW.M};
  z-index: 2;
`

const StyledP = styled(P)`
  padding: ${GTR.XS} ${GTR.S};
  margin: 0;

  &:focus-within {
    background-color: ${COLOR.BACKGROUND.SMOKE};
  }
`

const StyledLink = styled(Link)<{ $bold?: boolean }>`
  text-decoration: none;

  ${({ $bold }) => $bold && `font-weight: ${FONT.WEIGHT.BOLD};`}

  &:focus {
    outline: none;
  }
`

interface SearchWithDropdownProps {
  data: {
    generalArticles: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.LocalSearchGeneralArticles, 'index' | 'store'>
    >
    companyArticles?: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.LocalSearchCompanyArticles, 'index' | 'store'>
    >
  }
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>
  handleFocus?: React.FocusEventHandler<HTMLInputElement>
}

const SearchWithDropdown = ({
  data,
  setShowOverlay,
  handleFocus,
}: SearchWithDropdownProps): React.ReactElement => {
  const [query, setQuery] = useState('')
  const [queryForDropdown, setQueryForDropdown] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [results, setResults] = useState<GatsbyTypes.ContentfulArticle[]>([])
  const [selectedResultIndex, setSelectedResultIndex] = useState<number>()
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout>()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const generalResults = useFlexSearch(
    queryForDropdown,
    data.generalArticles?.index,
    data.generalArticles?.store,
    { limit: MAX_SEARCH_RESULTS }
  )
  const companyResults = useFlexSearch(
    queryForDropdown,
    data.companyArticles?.index,
    data.companyArticles?.store,
    { limit: MAX_SEARCH_RESULTS }
  )

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.repeat) return
    // On arrow down press, select the next search result or link in the dropdown
    if (event.key === 'ArrowDown' && showDropdown) {
      event.preventDefault()
      const newIndex =
        selectedResultIndex === undefined ? 0 : selectedResultIndex + 1

      if (newIndex <= MAX_SEARCH_RESULTS) {
        setSelectedResultIndex(newIndex)
        const searchResults = dropdownRef.current?.lastElementChild

        if (searchResults?.childNodes) {
          // If there are no results we want to go straight to the last item
          // in the dropdown which will be a link to all articles
          const selectedResult = (
            results.length
              ? searchResults.childNodes[newIndex].firstChild
              : searchResults.lastElementChild?.firstChild
          ) as HTMLElement
          selectedResult.focus()
        }
      }
    }

    // On arrow up press, select the previous search result in the dropdown
    // or go back to the search input if the top result selected
    if (event.key === 'ArrowUp' && showDropdown) {
      event.preventDefault()
      if (selectedResultIndex === undefined) return
      if (selectedResultIndex === 0 || results.length === 0) {
        const inputElement = dropdownRef.current?.firstElementChild
          ?.firstElementChild as HTMLElement
        if (inputElement) {
          setSelectedResultIndex(undefined)
          inputElement.focus()
        }
      } else {
        const newIndex = selectedResultIndex - 1
        setSelectedResultIndex(newIndex)

        const searchResults = dropdownRef.current?.lastElementChild
        if (searchResults?.childNodes) {
          const selectedResult = searchResults.childNodes[newIndex]
            .firstChild as HTMLElement
          selectedResult.focus()
        }
      }
    }
  }

  const handleFocusChange = (event: FocusEvent) => {
    if (
      showDropdown &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false)
      setSelectedResultIndex(undefined)
    } else if (
      !showDropdown &&
      dropdownRef.current &&
      dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(query.length > 2)
    }
  }

  useEffect(() => {
    setShowOverlay(showDropdown)
  }, [showDropdown])

  useEffect(() => {
    setResults([...generalResults, ...companyResults])
  }, [generalResults, companyResults])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [handleKeydown])

  useEffect(() => {
    document.addEventListener('focus', handleFocusChange, true)
    return () => document.removeEventListener('focus', handleFocusChange, true)
  }, [handleFocusChange])

  const goToSearchResultsPage = (): void => {
    navigate(
      `${PATHS.GENERAL.CONTENT_HUB_SEARCH}/?${qs.stringify({
        query,
      })}`
    )
  }

  const handleSearchBoxKeydown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') goToSearchResultsPage()
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.persist()
    setSelectedResultIndex(undefined)
    setQuery(event.target.value)
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    setDebounceTimeout(
      setTimeout(() => {
        setQueryForDropdown(event.target.value)
        setShowDropdown(event.target.value.length > 2)
      }, 300)
    )
  }

  return (
    <StyledWrapper>
      <StyledDiv ref={dropdownRef}>
        <SearchBox
          onKeyDown={handleSearchBoxKeydown}
          onChange={handleChange}
          onFocus={handleFocus}
          onButtonClick={() => goToSearchResultsPage()}
        />
        {showDropdown && (
          <StyledDropdown>
            {results.length > 0 ? (
              <>
                {results.slice(0, MAX_SEARCH_RESULTS).map((result) => (
                  <StyledP key={result.id}>
                    <StyledLink
                      to={`${
                        result.type === ARTICLE_TYPES.BLOG
                          ? '/blog'
                          : '/articles'
                      }/${result.slug}`}
                      tabIndex={-1}
                    >
                      {result.title}
                    </StyledLink>
                  </StyledP>
                ))}
                <StyledP>
                  <StyledLink
                    to={`${PATHS.GENERAL.CONTENT_HUB_SEARCH}/?${qs.stringify({
                      query,
                    })}`}
                    tabIndex={-1}
                    $bold
                  >
                    See all results <ArrowRightIcon inline />
                  </StyledLink>
                </StyledP>
              </>
            ) : (
              <>
                <StyledP>No results for “{query}”</StyledP>
                <StyledP>
                  <StyledLink
                    to={`${PATHS.GENERAL.CONTENT_HUB_SEARCH}`}
                    tabIndex={-1}
                    $bold
                  >
                    See all guides <ArrowRightIcon inline />
                  </StyledLink>
                </StyledP>
              </>
            )}
          </StyledDropdown>
        )}
      </StyledDiv>
    </StyledWrapper>
  )
}

export default SearchWithDropdown
