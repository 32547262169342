import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { H, P, Image, Wrapper } from '@farewill/ui'
import { BORDER_RADIUS, COLOR } from '@farewill/ui/tokens'
import { screen } from '@farewill/ui/helpers/responsive'
import { ToolsItem } from '../types'

const StyledCard = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER_RADIUS.S};
  height: 100%;
`

const StyledImageWrapper = styled(Wrapper)`
  position: relative;
  max-width: 300px;
  left: 50%;
  transform: translateX(-50%);

  ${screen.m`
    left: 0;
    transform: translateX(0);
  `}

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: none;

    h2 {
      text-decoration: underline;
    }
  }

  &:focus > div {
    outline: 3px solid ${COLOR.STATE.ACTIVE};
  }
`

const StyledIllustration = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
`

const SingleToolsItem = ({ data }: { data: ToolsItem }): React.ReactElement => (
  <StyledLink to={data.path}>
    <StyledCard
      container
      paddingFromM={['S', 'L', 'M']}
      paddingFromL={['L', 'L']}
    >
      <StyledImageWrapper>
        <StyledIllustration path={data.image} width={300} stretch />
      </StyledImageWrapper>
      <Wrapper margin={[0, 0, 'M']}>
        <H tag="h2" size="S">
          {data.title}
        </H>
        <P>{data.description}</P>
      </Wrapper>
    </StyledCard>
  </StyledLink>
)

export default SingleToolsItem
