import React from 'react'
import styled from 'styled-components'
import { H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import YellowBanner from 'components/YellowBanner'
import { MIN_BANNER_HEIGHT } from '../constants'

const StyledDivider = styled.div`
  width: 72px;
  height: 5px;
  border-radius: 2px;
  background-color: ${COLOR.BLACK};

  ${screenMin.m`
    margin: 0 auto;
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${MIN_BANNER_HEIGHT.LARGE_SCREENS};

  ${screenMax.s`
    min-height: ${MIN_BANNER_HEIGHT.SMALL_SCREENS};
    padding-bottom: ${GTR.XXL};
    align-items: start;
    text-align: left;
  `}
`

const Banner = (): React.ReactElement => (
  <YellowBanner textureStyle="A">
    <StyledWrapper>
      <H tag="h1" size="XL" decorative>
        The Farewell
      </H>
      <StyledDivider />
      <P size="L" color={COLOR.BLACK} margin={['M', 0, 0]}>
        Your guide through life’s toughest moments
      </P>
    </StyledWrapper>
  </YellowBanner>
)

export default Banner
