import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import { PRODUCTS } from 'lib/products/constants'
import DefaultLayout from 'layouts/DefaultLayout'
import Footer from 'views/general/templates/components/Footer'
import { TRUSTMARKS } from 'components/AccoladesProduct/constants'
import JSONLD from 'components/JSONLD'
import { CTA_TYPES } from 'components/Cta/constants'
import BreakpointSwitch from 'components/BreakpointSwitch'
import { FLOW_PROBATE_URL } from 'config'

import PATHS from 'paths'
import Banner from './components/Banner'
import Section from './components/Section'
import Tools from './components/Tools'
import ContentMenu from './components/ContentMenu'
import SearchWithDropdown from './components/SearchWithDropdown'
import { ToolsItem } from './types'
import FuneralPlansBanner from './components/FuneralPlansBanner'

const toolsContent: ToolsItem[] = [
  {
    id: 'tc-01',
    title: 'Find out if you need probate',
    description:
      'Finding out if you need probate can be confusing, so we created a free tool that checks for you. Answer a few questions to get started.',
    image: 'illustrations/square/house-with-keyhole',
    path: FLOW_PROBATE_URL,
  },
  {
    id: 'tc-02',
    title: 'Get an estimate for a funeral',
    description:
      'We specialise in direct cremations because they don’t restrict you to a formal service at the crematorium. Say goodbye your way, at a time and place that feels right.',
    image: 'illustrations/blob-with-laptop-and-cat',
    path: PATHS.CREMATION.DIRECT,
  },
]

const OpacityWrapper = styled(Wrapper)<{ $reduceOpacity: boolean }>`
  transition: opacity 0.1s;

  ${({ $reduceOpacity }) =>
    $reduceOpacity &&
    `
      opacity: 0.15;
      pointer-events: none;
    `}
`

interface ContentHubProps {
  data: GatsbyTypes.ContentHubQuery
}

const ContentHub = ({ data }: ContentHubProps): React.ReactElement => {
  const [hideContent, setHideContent] = useState(false)
  const contentMenuRef = useRef<HTMLDivElement>(null)

  const handleInputFocus = () => {
    if (contentMenuRef.current) {
      contentMenuRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const sections = Object.values(data).filter(
    (entry): entry is GatsbyTypes.SectionFragment =>
      !!entry &&
      '__typename' in entry &&
      // eslint-disable-next-line no-underscore-dangle
      entry.__typename === 'ContentfulContentHubCategoryGroup'
  )

  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />
      <DefaultLayout
        title="The Farewell"
        description="Your guide through life’s toughest moments"
      >
        <Wrapper background={COLOR.WHITE}>
          <Banner />
          <div ref={contentMenuRef}>
            <ContentMenu
              sections={sections}
              data={{
                generalArticles: data.localSearchGeneralArticles,
                companyArticles: data.localSearchCompanyArticles,
              }}
              setShowOverlay={setHideContent}
            />
          </div>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            belowComponent={
              <Wrapper padding={['L', 'M', 0]}>
                <SearchWithDropdown
                  data={{
                    generalArticles: data.localSearchGeneralArticles,
                    companyArticles: data.localSearchCompanyArticles,
                  }}
                  setShowOverlay={setHideContent}
                  handleFocus={handleInputFocus}
                />
              </Wrapper>
            }
          />
          <OpacityWrapper $reduceOpacity={hideContent}>
            <Wrapper>
              <FuneralPlansBanner />
              <Section content={data.Section1} />
              <Section
                background={COLOR.ACCENT.PRIMARY_10}
                content={data.Section2}
                hasBottomImages
              />
              <Section content={data.Section3} />

              <Section content={data.Section4} />
              <Tools title="Use our free tools" content={toolsContent} />
              <Section content={data.Section5} />
              <Section
                background={COLOR.ACCENT.PRIMARY_10}
                content={data.Section6}
                hasBottomImages
              />
            </Wrapper>
            <Footer
              title="The UK’s best-rated death specialists"
              description="At Farewill we’re making everything to do with death easier, friendlier and more affordable. We provide probate, wills and funerals with a difference, and so far we’ve helped over 60,000 families in the UK. "
              cta={{
                type: CTA_TYPES.LINK,
                text: 'Find out more',
                link: '/',
              }}
              trustmarks={TRUSTMARKS.PROBATE}
              product={PRODUCTS.PROBATE}
              background={COLOR.ACCENT.PRIMARY_10}
              smallText
            />
          </OpacityWrapper>
        </Wrapper>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  fragment HighlightedArticle on ContentfulArticle {
    contentful_id
    type
    title
    description {
      description
    }
    image {
      file {
        url
      }
    }
    slug
  }

  fragment Section on ContentfulContentHubCategoryGroup {
    __typename
    contentful_id
    title
    slug
    highlightedArticles {
      ...HighlightedArticle
    }
    categories {
      slug
    }
  }

  query ContentHub(
    $section1Id: String = "2Hmg5Qj6L5iMM6hlgMiAog"
    $section2Id: String = "33WF6eHoUJBLbL3PYQDtbc"
    $section3Id: String = "1ErzUkA9SnwDz48UTiW3nV"
    $section4Id: String = "3KuLcBf4bE2ocDBsRJ3oVB"
    $section5Id: String = "56Rurf9nNcDLC0POFTjbdF"
    $section6Id: String = "679gC4rSv9unkYHYjcmSkt"
  ) {
    Section1: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section1Id }
    ) {
      ...Section
    }
    Section2: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section2Id }
    ) {
      ...Section
    }
    Section3: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section3Id }
    ) {
      ...Section
    }
    Section4: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section4Id }
    ) {
      ...Section
    }
    Section5: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section5Id }
    ) {
      ...Section
    }
    Section6: contentfulContentHubCategoryGroup(
      contentful_id: { eq: $section6Id }
    ) {
      ...Section
    }
    localSearchCompanyArticles {
      index
      store
    }
    localSearchGeneralArticles {
      index
      store
    }
  }
`

export default ContentHub
