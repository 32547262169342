import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { Button, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import PATHS from 'paths'

const StyledWrapper = styled(Wrapper)`
  border-radius: ${GTR.S};
`
const StyledGrid = styled(Grid)`
  align-items: center;
  column-gap: 0;

  & > div:first-child {
    ${screenMax.s`
    order: 2;
    margin-top: ${GTR.M};
    `}
  }

  & > div:last-child {
    ${screenMax.s`
    order: 1;
    `}
  }
`

const StyledButton = styled(Button.Primary)`
  ${screenMax.s`
    width: 100%;
  `}
`

const FuneralPlansBanner = (): ReactElement => (
  <Wrapper container containerPaddingBottom={0}>
    <StyledWrapper
      background={COLOR.ACCENT.PRIMARY_20}
      padding={['XL', 'M']}
      paddingFromM={['XL', 'L']}
      paddingFromXL={['XXL', 'XL']}
    >
      <StyledGrid>
        <Grid.Item spanFromM={4} spanFromL={5} centered>
          <Image
            path="com-assets/guide-brochure/guide-printed-mock"
            width={220}
            widthFromM={200}
            widthFromL={300}
          />
        </Grid.Item>
        <Grid.Item startColumnFromM={6} startColumnFromL={7} spanFromL={6}>
          <H size="L" margin={[0, 0, 'S']} decorative>
            Say goodbye your way
          </H>
          <P margin={[0, 0, 'M']} size="L" maxWidth={460}>
            A funeral plan is a way to plan and pay for all the essentials of a
            funeral in advance. It takes away the financial and emotional burden
            from your loved ones.
          </P>

          <StyledButton tag={Link} to={PATHS.CREMATION.FUNERAL_PLANS}>
            Find out more
          </StyledButton>
        </Grid.Item>
      </StyledGrid>
    </StyledWrapper>
  </Wrapper>
)

export default FuneralPlansBanner
