import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { H, Wrapper, Image, Grid } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { ToolsItem } from '../types'
import SingleToolsItem from './SingleToolsItem'
import Scroll from './Scroll'

const StyledMediaWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
`

const StyledTextureTopRight = styled(StyledTexture)`
  top: 0;
  right: 0;
  transform: rotate(180deg);

  ${screenMax.m`
    max-width: 150px;
  `};
`

const StyledTextureBottomRight = styled(StyledTexture)`
  bottom: 0;
  right: -10%;

  ${screenMax.m`
    max-width: 150px;
  `}
`

const StyledTextureTopLeft = styled(StyledTexture)`
  bottom: 0;
  left: -5%;
  transform: scale(-1, -1);

  ${screenMax.m`
    max-width: 250px;
  `}
`

interface MediaProps {
  content: ToolsItem[]
  title: string
}

const Tools = ({ title, content }: MediaProps): React.ReactElement => {
  const headingRef = useRef<HTMLDivElement>(null)
  const [scrollIndent, setScrollIndent] = useState(
    headingRef.current?.offsetLeft || 0
  )

  const updateScrollIndent = () => {
    setScrollIndent(headingRef.current?.offsetLeft || 0)
  }

  useEffect(() => {
    updateScrollIndent()
  }, [headingRef])

  useEffect(() => {
    window.addEventListener('resize', updateScrollIndent)
    return () => window.removeEventListener('resize', updateScrollIndent)
  }, [])

  return (
    <StyledMediaWrapper background={COLOR.ACCENT.PRIMARY} padding={['XXL', 0]}>
      <StyledTextureBottomRight
        path="textures/fingerprint_yellow02"
        width={550}
        loading="auto"
        formatPath={({ path, ext, width }: FormatPathArgs) =>
          `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_270,w_${width}/${path}.${ext}`
        }
      />
      <StyledTextureTopRight
        path="textures/detail-1-light"
        width={300}
        loading="auto"
      />
      <StyledTextureTopLeft
        path="textures/detail-4-dark"
        width={700}
        loading="auto"
      />
      <StyledMediaWrapper>
        <Wrapper container>
          <Grid>
            <Grid.Item startColumnFromM={2} startColumnFromL={1}>
              <div ref={headingRef}>
                <H tag="h2" size="M" decorative margin={0}>
                  {title}
                </H>
              </div>
            </Grid.Item>
          </Grid>
        </Wrapper>
        <Scroll items={content.length} indent={scrollIndent}>
          {content.map((article) => (
            <SingleToolsItem key={article.id} data={article} />
          ))}
        </Scroll>
      </StyledMediaWrapper>
    </StyledMediaWrapper>
  )
}

export default Tools
